// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import CardSettingsBlockCardFirstStepActionsHook from './hooks/card-settings-block-card-first-step-actions.hook';

const CardSettingsBlockCardFirstStepActionsComponent = (): React.ReactElement => {
  const { blockReason } = CardSettingsBlockCardFirstStepActionsHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: !blockReason,
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export default CardSettingsBlockCardFirstStepActionsComponent;
